.toolbar {
  background-color: #d6d7eb;
  // height: 80px;
  padding: 10px 5px;
  margin-top: -20px;
}

.toolbar-Form {
  background-color: #fff;
}

.dx-button-content {
  font-size: 12px !important;
  padding: 6px !important;
}

.toolbar .dx-button-content .dx-icon {
  font-size: 16px !important;
  margin-right: 0 !important;
}

.toolbar .dx-toolbar-before:last-child .dx-button-content {
  margin-top: 3px !important;
}
