.toolbar-Form {
  background-color: #fff;
  margin-top: 20px;
  //   display: none;
}

.edit-btn {
  border: 0.5px solid #80808074;
  padding: 0.3rem 1rem !important;
  height: fit-content !important;
  font-size: 14px !important;

  &:hover {
    background-color: #7fffd48a;
  }
}

.group-caption {
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: -40px;
}

.grid-view-btn {
  background: rgb(232, 232, 232) !important;
  padding: 5px 15px !important;
  color: rgb(9, 68, 216);
  cursor: pointer;
  &:hover {
    background: rgb(9, 68, 216) !important;
    color: #fff;
  }
}

.hidden-btn {
  visibility: hidden !important;
}
