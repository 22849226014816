svg {
  fill: rgb(242, 242, 242);
  /* height: 3.6rem;
  width: 3.6rem; */
  margin: 0.2rem;
}

.rating-form-2 svg {
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
}

input[type='radio'] + svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  height: 3.6rem;
  width: 3.6rem;
}

input + svg {
  cursor: pointer;
}

input[class='pass']:hover + svg,
input[class='pass']:checked + svg,
input[class='pass']:focus + svg {
  fill: rgb(0, 204, 79);
}

input[class='pending']:hover + svg,
input[class='pending']:checked + svg,
input[class='pending']:focus + svg {
  fill: rgb(229, 132, 0);
}

input[class='fail']:hover + svg,
input[class='fail']:checked + svg,
input[class='fail']:focus + svg {
  fill: rgb(239, 42, 16);
}
