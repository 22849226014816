.form-container {
  background-color: #fff;
  padding: 1rem;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

.dx-field-item-label-text {
  font-weight: bold;
}

.details-header {
  margin: 2rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  border-bottom: 3px solid grey;
  padding-bottom: 1rem;
}
