.side-nav-outer-toolbar {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;

  .drawer {
    height: calc(100% - 32px) !important;

    &.drawer-wrapper {
      height: 100% !important;
      .dx-drawer-content {
        overflow-y: auto;
      }
    }

    //menu items dropdown icons
    .dx-swatch-additional .dx-treeview-toggle-item-visibility {
      height: 36px !important;
    }

    .dx-swatch-additional .dx-treeview-item .dx-treeview-item-content span {
      font-size: 13px !important;
    }

    .dx-popup-content {
      height: 100% !important;
    }

    .dx-popover-arrow {
      inset: 65px auto auto -10px !important;
    }

    .container {
      height: 100%;
      overflow-y: auto;
      .footer {
        border-top: none;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  @media print {
    .container {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

// DEDICATED TRADEFIGURE LAYOUT STYLING
.tradefigure-layout {
  .dx-overlay-shader {
    background-color: #15142361 !important;
  }

  .dx-popup-title.dx-toolbar {
    background-color: #7f8899;
    color: #fff;

    .dx-closebutton .dx-icon {
      color: #fff;
    }
  }

  .dx-form-group-caption {
    color: #7f8899;
  }

  @media print {
    .container {
      &.dx-drawer-panel-content {
        display: none;
      }
    }
  }
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

//content section
.main-content-layout {
  flex-grow: 1;
  display: flex;
  min-height: calc(100% - 100px);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  transition: all 0.3s ease;

  //Common layout style
  .main-content {
    flex-grow: 1;
    transition: all 0.3s ease;
    width: calc(100% - 200px);
    flex-grow: 1;
    height: 100%;
    // background-color: #c0cbb0e6;
    // background-color: #616045fc;
  }
}
.page-ui {
  height: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  @media print {
    .dx-drawer-shader .dx-state-invisible,
    .page-ui-header {
      display: none;
    }
  }

  .page-ui-header {
    padding: 0.5rem 2rem 0.5rem 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #1f283f;
    width: fit-content;
    background-color: #fff;
    border-radius: 0 40px 0 0;
    clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 100% 100%, 0% 100%);
  }

  .module-wrapper {
    height: calc(100% - 30px);
    padding: 2rem 1rem 1rem 1rem;
    background-color: #fff;
    min-height: 480px;
  }
}
//reset header styles
.dx-toolbar .dx-toolbar-items-container {
  height: 60px;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 20px;
}
