.search-component {
  width: 100%;
  max-width: 500px;
  /* background-color: rgb(242, 242, 242); */
  padding: 1rem 1rem 2rem 1rem;
}

.search-component label {
  display: block;
  font-weight: bold;
}

.search-component #email {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 5px;
  margin: 5px 0 1rem 0;
  outline: none;
  border: 1px solid grey;
}

.search-component #email:focus {
  border: 1px solid rgb(81, 123, 102);
}
.submit-btn {
  max-width: 120px;
  width: 100%;
  padding: 10px;
  border: none;
  color: #fff;
  background-color: rgb(13, 157, 13);
  display: block;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(10, 118, 10);
}

.set-role-btn {
  margin-top: 2rem;
}

.user-search-result {
  /* margin-top: 4rem; */
  background-color: rgb(242, 242, 242);
  border-top: 4px solid rgb(174, 174, 174);
  border-bottom: 2px solid rgb(174, 174, 174);
  padding: 2rem;
}

.user-search-result b {
  font-weight: bold;
  display: inline-block;
  margin-right: 20px;
}

.search-item {
  margin: 20px 0;
}

.roles-section {
  margin-top: 2rem;
  max-width: 400px;
  width: 100%;
}
