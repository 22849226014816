#treeView,
#product-details {
  display: inline-block;
}
#treeView {
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
}
#product-details {
  vertical-align: top;
  width: 100%;
  /* height: 420px; */
  margin-left: 20px;
}
.dx-treeview-item {
  display: block;
  cursor: pointer;
  padding: 5px 6px;
  min-height: 32px;
  color: black;
}
.name {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.price {
  color: #fff;
}

/* #product-details > img {
  border: none;
  height: 200px;
  width: 300px;
} */
/* 
#product-details > .name {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
 
} */

#product-details > .report {
  text-align: center;

  font-size: 20px;
}

/* .hidden {
  visibility: hidden;
} */

#chart {
  height: 450px;
  width: 80%;
}
.chartbox {
  display: flex;
}
/* .card-header {
  display: flex;
} */
.pending {
  font-size: 20px;
  font-weight: bold;
}

.custom-margin-class {
  display: flex;
  margin: auto;
  gap: 20px;
}
.text {
  display: flex;
  justify-content: right;
  align-items: center;
}
.form-text {
  margin-right: 18px;
}
@media screen and (max-width: 480px) {
  .custom-margin-class {
    flex-direction: column-reverse;
  }

  .card-class {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto !important;
  }
  .chartbox {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .form-text {
    width: 300px;
  }
  .form-text Button {
    display: block !important;
    margin: auto !important;
    width: 100px;
  }
  /* .dx-texteditor-input {
    width: 70% !important;
  } */
  .two.fields:not(.unstackable) > .fields {
    width: 50% !important;
  }
  .message-text {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .two.fields:not(.unstackable) > .fields {
    width: 50% !important;
  }
}
