.h3 {
  width: fit-content;
  background-color: #27173a2c;
  margin-bottom: 0;
  padding: 0.5rem 2rem 0.3rem 0.5rem;
  border-radius: 0 15px 0 0;
  font-weight: bold;
  font-size: 1.3rem !important;
}
.wrapper {
  width: 100%;
  height: fit-content;
  padding: 1rem;
  margin: 2rem auto;
  overflow-x: auto;
}

.table {
  width: inherit;
  min-width: 520px;
}

.table th,
.table td {
  padding: 0.4rem;
  border: 0.4px solid gainsboro;
  text-align: left;
}

.table tr:hover {
  background-color: rgb(235, 235, 235);
}

.table th {
  background-color: #180f3141;
}

.table td:nth-child(7) button {
  margin: 0.2rem;
}

.select,
.input {
  min-width: 80px;
}
